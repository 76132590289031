<template>
  <div>
    <section class="page-title text-center mb-5">
      <div class="container">
        <div class="content-box">
          <h1>
            {{ $t('mypackages') }}
          </h1>
        </div>
      </div>
    </section>
    <div style="background-color: white; padding-top: 36px; padding-bottom: 150px">
      <div class="container">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col" style="width:100px">#</th>
              <th scope="col">{{ $t('First Name') }}</th>
              <th scope="col">{{ $t('Last Name') }}</th>
              <th scope="col">{{ $t('Payment Method') }}</th>
              <th scope="col">{{ $t('Package') }}</th>
              <th scope="col">{{ $t('Plan') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item of list">
              <th scope="row">{{ $codePadding(item.id) }}</th>
              <td>{{ item.fname }}</td>
              <td>{{ item.lname }}</td>
              <td>
                {{ item.paymentMethod }}
                -
                {{ item.usersId.paymentDone ? "And Done" : "But Not Complite" }}
              </td>
              <td>
                {{ $getByLang(item.packagesId.name) }}
              </td>
              <td>
                {{ $getByLang(item.packagesPricesId.name) }} ({{
                  item.packagesPricesId.price
                }}
                €)
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      list: [],
    };
  },

  methods: {},
  async created() {
    const user = JSON.parse(localStorage.userKB);

    this.$http.get("packages_users?usersId=" + user.id).then((res) => {
      this.list = res.data.data;
    });
  },
};
</script>

<style></style>
